import clsx from "clsx"
import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from "react"

function scrollTo(
	parent: RefObject<HTMLDivElement>,
	children: MutableRefObject<HTMLDivElement[]>,
	target: number
): void {
	const item = children.current[(target + children.current.length) % children.current.length]
	parent?.current?.scrollTo(item?.offsetLeft, 0)
}

const ImageSlider = (props: { images: string[], className?: string }) => {
	const [selectedImage, setSelectedImage] = useState(0);
	const [pauseRotation, setPauseRotation] = useState(false);

	const ref = useRef<HTMLDivElement>(null)

	const itemsRef = useRef([] as HTMLDivElement[]);
	useEffect(() => {
		itemsRef.current = itemsRef.current.slice(0, props.images.length);
	}, [props.images]);


	useEffect(() => {
		if (pauseRotation)
			return;

		const timeout = setTimeout(() => {
			scrollTo(
				ref,
				itemsRef,
				selectedImage + 1
			)
		}, 5000)
		return () => {
			clearTimeout(timeout);
		}
	}, [selectedImage, props.images?.length, pauseRotation]);
	const stopRotation = useCallback(() => setPauseRotation(true), [setPauseRotation])
	const startRotation = useCallback(() => setPauseRotation(false), [setPauseRotation])
	return <div
		className={clsx('slider', props.className)}
	>
		<img draggable="false" alt="" src={props.images[0]} />
		<div
			className="inner"
			onScroll={(e) => setSelectedImage(
				Math.round(
					(e.target as any).scrollLeft / ((ref.current?.getBoundingClientRect().width) ?? 1)
				)
			)}
			ref={ref}

		>
			{props.images?.map((image, i) =>
				<img
					draggable="false"
					alt=""
					key={`${i}:${image}`}
					src={image}
					className={clsx(i === selectedImage && 'selected')}
					ref={el => itemsRef.current[i] = el!}
					onTouchStart={stopRotation}
					onTouchEnd={startRotation}
					onMouseEnter={stopRotation}
					onMouseLeave={startRotation}
					onContextMenu={(e) => e.preventDefault()}
				/>
			)}

		</div>
		<div className={clsx("arrow left", selectedImage === 0 && 'hidden')} onClick={() => scrollTo(ref, itemsRef, selectedImage - 1)} />
		<div className={clsx("arrow right", selectedImage === props.images.length - 1 && 'hidden')} onClick={() => scrollTo(ref, itemsRef, selectedImage + 1)} />
		<div className="dots">
			{props.images?.map((_, i) => <div className={clsx('dot', i === selectedImage && 'selected')} key={i} />)}
		</div>
	</div >
}

export default ImageSlider
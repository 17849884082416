import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './App.sass';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Invites from './Invites';

const Fallback = () => {
  return <div className="fallback"></div>
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Fallback />,
  },
  {
    path: "/*",
    element: <App />,
  },
  {
    path: "/links",
    element: <Invites />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener("resize", (event) => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);



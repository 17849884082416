import { encode, decode } from 'js-base64';

export enum Language {
	English,
	Russian,
	Japanese,
}

export interface InviteData {
	language: Language
	names: string
}

export function getInviteData(location: string): InviteData | null {
	try {
		return JSON.parse(decode(location.slice(1))) as InviteData
	} catch {
		return null;
	}
}

export function createInviteData(data: InviteData): string {
	return encode(JSON.stringify(data))
}

import clsx from "clsx"

export enum DividerType {
	Heart = "heart",
	Cube = "cube_center"
}

export const Divider = (props: { type: DividerType, className?: string, negative?: boolean }) => {
	return <div
		className={clsx("divider", props.className, props.negative && "negative")}>
		<img
			draggable="false"
			alt="divider"
			src={`${process.env.PUBLIC_URL}/dividers/${props.type}.svg`}
		/>
	</div>
}
